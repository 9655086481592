// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, parseStyleProps, } from '@haaretz/htz-css-tools';
import EventTracker from '../../../utils/EventTracker';
import A11yDialog from '../../A11yDialog/A11yDialog';
import Button from '../../Button/Button';
import IconClose from '../../Icon/icons/IconClose';
import Balls from '../../illustrations/Balls/Balls';

type PropsType = {
  isVisible: boolean,
  title?: ?string,
  subtitle?: ?string,
  actionText?: ?string,
  actionTarget?: ?(string | () => void),
  featureName?: string,
  onClose: () => void,
}

const bodyStyle = ({ theme, }) => ({
  display: 'grid',
  gridTemplateAreas: '"close content"',
  extend: [
    theme.mq({ until: 's', }, {
      gridTemplateAreas: `
"close"
"content"`,
    }),
    theme.mq({ from: 's', }, { gridTemplateAreas: '"close content"', }),
  ],
});


const contentWrapperStyle = ({ theme, }) => ({
  gridArea: 'content',
  zIndex: 1,
  textAlign: 'center',
  fontFamily: theme.fontStacks.alt,
  textTransform: 'uppercase',
  alignSelf: 'center',
  extend: [
    theme.mq({ until: 's', }, {
      marginTop: '7rem',
      marginInlineStart: '4rem',
      marginInlineEnd: '7rem',
    }),
    theme.mq({ from: 's', }, {
      marginTop: '9rem',
      marginInlineStart: '5.3rem',
      marginInlineEnd: '22.65rem',
    }),
  ],
});

function titleStyle({ theme, }) {
  return {
    fontWeight: 700,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    extend: [
      theme.type(1, { until: 's', }),
      theme.type(4, { from: 's', }),
      theme.mq({ until: 's', }, {
        maxWidth: '33rem',
      }),
      theme.mq({ from: 's', }, {
        maxWidth: '43rem',
      }),
    ],
  };
}

function subtitleStyle({ theme, }) {
  return {
    fontWeight: 400,
    marginTop: '1rem',
    marginInlineStart: 'auto',
    marginInlineEnd: 'auto',
    extend: [
      theme.type(-1, { until: 's', }),
      theme.type(1, { from: 's', }),
      theme.mq({ until: 's', }, {
        maxWidth: '24rem',
      }),
      theme.mq({ from: 's', }, {
        maxWidth: '38rem',
      }),
    ],
  };
}

FeatureDisabledDialog.defaultProps = {
  title: null,
  subtitle: null,
  actionText: null,
  actionTarget: null,
  featureName: '',
};

export default function FeatureDisabledDialog({
  title,
  subtitle,
  actionText,
  actionTarget,
  featureName,
  isVisible,
  onClose,
}: PropsType) {
  const { css, theme, } = useFela();

  return (
    <A11yDialog
      appendTo="share_bar_modal"
      overlayBgColor={theme.shareBarPopup.overlayBgColor}
      elementToHide="pageRoot"
      isVisible={isVisible}
      containerMiscStyles={{ outline: 'none', }}
      closeOnOutsideClick
      onClose={onClose}
      isModal
      render={({ isVisible, handleClose, }) => (
        <div className={css(bodyStyle)}>
          <Button
            isFlat
            boxModel={{ hp: 1, vp: 1, }}
            onClick={onClose}
            variant="inverse"
            miscStyles={{
              zIndex: 2,
              alignSelf: 'start',
              justifySelf: 'start',
              marginTop: [ { from: 's', value: '7rem', }, ],
              marginBottom: [ { until: 's', value: '-7rem', }, ],
            }}
          >
            <IconClose size={7} color="quaternary" />
          </Button>
          <Balls
            size={[ { until: 's', value: 60, }, { from: 's', value: 83, }, ]}
            primaryColor={theme.color('quaternary')}
            secondaryColor="#EFE2D3"
            miscStyles={{
              gridArea: 'content',
              maxWidth: [ { until: 's', value: 'calc(100vw - 1rem)', }, ],
            }}
          />
          <div className={css(contentWrapperStyle)}>
            <h3 className={css(titleStyle)}>{title}</h3>
            {subtitle
              ? <p className={css(subtitleStyle)}>{subtitle}</p>
              : null}

            <EventTracker>
              {({ biAction, gaAction, }) => (
                <Button
                  attrs={{
                    'data-test': 'astronautButton',
                  }}
                  href={(actionTarget && typeof actionTarget) === 'string' ? actionTarget : null}
                  miscStyles={{
                    marginTop: '2rem',
                  }}
                  onClick={() => {
                    biAction && biAction({
                      actionCode: 3,
                      feature: 'Reading',
                      featureType: 'Content',
                      campaignName: featureName,
                      campaignDetails: actionText,
                    });

                    if (actionTarget && typeof actionTarget === 'function') {
                      actionTarget();
                    }
                  }}
                  variant="salesOpaque"
                >
                  {theme.zenNonPayingUsers.button}
                </Button>
              )}
            </EventTracker>
          </div>
        </div>
      )}
    />
  );
}
