// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import type { ComponentPropResponsiveObject, StyleProps, } from '@haaretz/htz-css-tools';

import useMedia from '../../../hooks/useMedia';

type PropsType = {
    size: ?number | ComponentPropResponsiveObject<number>[],
    /**
     * A string of a valid css color value (not the named theme color)
     */
    primaryColor: ?string,
    /**
     * A string of a valid css color value (not the named theme color)
     */
    secondaryColor: ?string,
    miscStyles: ?StyleProps,
};

function style({ theme, size, miscStyles, }) {
  return {
    extend: [
      parseComponentProp('size', size, theme.mq, setSize),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
}

function setSize(size, value) {
  return {
    fontSize: `${value}rem`,
  };
}

Balls.defaultProps = {
  primaryColor: '#EFE2D3',
  secondaryColor: '#2064FF',
};

export default function Balls({
  size,
  primaryColor,
  secondaryColor,
  miscStyles,
}: PropsType) {
  const { css, } = useFela({ size, miscStyles, });
  const isMobile = useMedia({ query: { until: 's', }, });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height={isMobile ? '0.89375em' : '0.7409em'}
      viewBox={isMobile ? '0 0 480 429' : '0 0 579 429'}
      className={css(style)}
      data-test="balls"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="229" cy="237" r="192" fill={primaryColor} />
        <circle cx="57" cy="125" r="23" fill={secondaryColor} />
        <circle cx="15" cy="254" r="15" fill={primaryColor} />
        <circle cx="453.5" cy="292.5" r="25.5" fill={primaryColor} />
        <circle cx="402" cy="152" r="32" fill={primaryColor} />
        {isMobile ? null : <circle cx="556" cy="28" r="23" fill={secondaryColor} />}
        <circle cx="139.5" cy="10.5" r="10.5" fill={secondaryColor} />
        <circle cx="401.5" cy="67.5" r="10.5" fill={primaryColor} />
        <circle cx="352" cy="423" r="5" fill={secondaryColor} />
        <circle cx="96" cy="389" r="5" fill={primaryColor} />
      </g>
    </svg>
  );
}
